window.dataLayer = window.dataLayer || [];

const mainMenuButtons = document.querySelectorAll('#menu-1-33fe25a > li > a');
mainMenuButtons.forEach((button)=> {
    button.addEventListener('click', ()=> {
        window.dataLayer.push({
            'event': 'header_button',
            'button_text': button.innerHTML,
            'button_link': button.href
        });
    });
});

const footerMenuButtons = document.querySelectorAll('.footer-menus a');
footerMenuButtons.forEach((button)=> {
    button.addEventListener('click', ()=> {
        window.dataLayer.push({
            'event': 'footer_button',
            'button_text': button.innerHTML,
            'button_link': button.href
        });
    });
});

const mainSearchGTMdata = () => {
    const search = document.querySelector('#search-filter-form-4964 .sf-input-text');
    const debounce = (fn, delay) => {
        let timeoutID;
        return function() {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
            timeoutID = setTimeout(() => {
                fn();
            }, delay)
        }
    }
    const pushData = () => {
        window.dataLayer.push({
            'event': 'website_search',
            'search_text': search.value
        });
        mainSearchGTMdata();
    }
    search.addEventListener('keyup', debounce(e => { pushData() }, 3500));
};
mainSearchGTMdata();

const eventsGTMdata = () => {
    const search = document.querySelector('#search-filter-form-6769 .sf-input-text');
    const type = document.querySelector('#search-filter-form-6769 .sf-field-taxonomy-event_type .sf-input-select');
    const topic = document.querySelector('#search-filter-form-6769 .sf-field-taxonomy-event_topic .sf-input-select');
    const debounce = (fn, delay) => {
        let timeoutID;
        return function() {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
            timeoutID = setTimeout(() => {
                fn();
            }, delay)
        }
    }
    const pushData = () => {
        window.dataLayer.push({
            'event': 'event_search',
            'search_text': search.value,
            'event_type': type.options[type.selectedIndex].text,
            'event_topic': topic.options[topic.selectedIndex].text,
        });
    }
    search.addEventListener('keyup', debounce(e => { pushData() }, 1200));
    type.addEventListener('change', debounce(e => { pushData() }, 1200));
    topic.addEventListener('change', debounce(e => { pushData() }, 1200));
}
eventsGTMdata();

const eventRegisterButtons = document.querySelectorAll('.event-register-button a');
eventRegisterButtons.forEach((button)=> {
    button.addEventListener('click', ()=> {
        window.dataLayer.push({
            'event': 'register_button'
        });
    });
});

const eventDirectionButton = document.querySelectorAll('.edge-directions-button a');
eventDirectionButton.forEach((button)=> {
    button.addEventListener('click', ()=> {
        window.dataLayer.push({
            'event': 'directions_button'
        });
    });
});

const eventFormSubmitButton = document.querySelector('.event-single-registration-section .gform_footer input');
const eventTitle = document.querySelector('.single-events .elementor-widget-theme-post-title .elementor-heading-title');
eventFormSubmitButton.addEventListener('click', ()=> {
    window.dataLayer.push({
        'event': 'save_my_spot',
        'event_name': eventTitle
    });
});
